<template>
	<div id="tutor">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>教务</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">排课列表</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="content">
				<div class="suguan">
					<div class="title u-f-item u-f-jsb">
						<div>
							共{{ total }}条数据
						</div>
						<div class="u-f-item">
							<el-select clearable v-model="gradeId" placeholder="请选择年级" size="small"
								style="width: 120px;margin-right: 10px;" @change="gradecn1">
								<el-option v-for="item in gradeList" :key="item.id" :label="item.name"
									:value="item.id"></el-option>
							</el-select>
							<el-select clearable v-model="calssname" placeholder="请选择班级" size="small"
								style="width: 120px;margin-right: 10px;" @change="classcn1">
								<el-option v-for="item in classList" :key="item.id" :label="item.name"
									:value="item.id"></el-option>
							</el-select>
							<el-button size='small' type="primary" @click="sosuo">筛选</el-button>

							<el-button size="small" @click="openUpdate()">批量录入</el-button>
						</div>
					</div>
					<el-table :data="list" height="98%" ref="multipleTable" tooltip-effect="dark"
						style="width: 99.6%;border: 1px solid #EEEEEE;">
						<el-table-column label="序号" type="index" width="80" align="center"></el-table-column>
						<el-table-column prop="title" label="类型" align="center">
							<template slot-scope="scope">
								<span>{{typeAttr[scope.row.type]}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="number" label="第几节" align="center"></el-table-column>
						<el-table-column prop="class_name" label="班级" align="center"></el-table-column>
						<el-table-column prop="" label="星期" align="center">
							<template slot-scope="scope">
								<span>{{dayAttr[scope.row.day_number]}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="evening_study_type" label="晚修类型" align="center"></el-table-column>
						<el-table-column prop="user_name" label="老师" align="center"></el-table-column>
						<el-table-column prop="teacher_type" label="老师类型" align="center"></el-table-column>

						<el-table-column prop="createtime" label=" 创建时间" align="center">

						</el-table-column>


					</el-table>
					<div class="u-f-right" style="padding: 15px 0;">
						<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
							:current-page.sync="page" :page-size="limit" layout="total, prev, pager, next"
							:total="total"></el-pagination>
					</div>
				</div>
			</div>

			<!-- 编辑人员 -->

		</index>

		<el-dialog title="批量导入" :visible.sync="updateVisible" width="500px" :before-close="handleClose">
			<div class="update_box">
				<div class="update_item">
					<div>请按照模板格式进行录入</div>
					<div class="u-f-item" style="margin-top: 5px;">
						<i class="iconfont icon-excel" style="color: red;margin-right: 3px;"></i>
						<a :href="fileUrl">下载模板</a>
					</div>
				</div>
				<div class="u-f" style="margin-top: 20px;">
					<div style="color: #B7BDC0;margin-right: 10px;margin-top: 5px;">数据录入</div>
					<div>
						<el-button size="mini" style="position: relative;"><input ref="filElem" type="file"
								class="upload-file" @change="getFile">点击上传</el-button>
						<div class="u-f-item u-f-jsb file_box" v-if="file.name" style="margin-top: 10px;width: 300px;">
							<div class="u-f-item">
								<i class="iconfont icon-excel" style="color: red;margin-right: 3px;"></i>{{file.name}}
							</div>
							<i class="el-icon-circle-check" style="color: #67C23A;"></i>
							<i class="el-icon-circle-close" style="color: #67C23A;" @click="delFile"></i>
						</div>
					</div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
				<el-button @click="updateVisible=false" size="small">取 消</el-button>
				<el-button type="primary" size="small" @click="gengxin">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import index from '@/components/common/index.vue';
	export default {
		components: {
			index
		},
		data() {
			return {
				loading: true,
				typeAttr: ['', '早读', '午练', '晚修'],
				dayAttr: ['星期天', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],

				teacherIndex: 2,
				page: 1,
				limit: 10,
				total: 0,
				list: [],
				grade_id: '',
				feedback: 0,
				development: 0,
				grade_name: '',
				user_id: '',
				user_name: '',
				gradeList: [],
				tutorList: [],
				key: '',
				updateVisible: false,
				classList: [],
				class_id: '',
				class_name: '',
				student_user_id: '',
				student_user_name: '',
				studentList: [],
				name: '',
				importVisible: false,
				file: '',
				gradeId: '',
				calssname: '',
				time: [],
				fileUrl: 'http://hlgzx.hzhlgzx.net/download/export/inport_scheduling.xls'
			};
		},
		mounted() {
			this.modifyList()
			this.getgradeList()
			this.getClassList()
		},
		methods: {
			openUpdate() {
				this.updateVisible = true
				// this.$api.student.ImportTempStu({}).then(res => {
				// 	if (res.data.code == 1) {
				// 		this.fileUrl = res.data.data.url;
				// 		this.updateVisible = true
				// 	}
				// })
			},
			getFile(e) {
				if (this.$refs.filElem.files.length) {
					this.file = this.$refs.filElem.files[0]
				}
			},

			gengxin() {
				let myfile = this.$refs.filElem;
				let files = myfile.files;
				let file = files[0];
				var formData = new FormData();
				formData.append("file", file);

				this.$api.sign.schedulingImport(formData).then(res => {
					if (res.data.code == 1) {
						this.$message.success('导入成功');
						this.modifyList()
						this.file = '';
						this.updateVisible = false;
					} else {
						this.$message.error(res.data.msg);
					}
				})
			},
			delFile() {
				this.file = '';
			},
			timeChange(e) {
				this.page = 1;
				this.modifyList()
			},
			gradecn1() {
				this.page = 1;
				this.calssname = '';
				this.getClassList()
				this.modifyList()
			},
			classcn1() {
				this.page = 1;
				this.modifyList()
			},
			// 添加
			handleAdd() {
				this.$refs.Form.open()
			},
			// 编辑
			handleEdit(row) {
				this.$refs.Form.open(row)
			},
			closeimportVisible() {
				this.importVisible = false;
			},
			// 导入
			imports() {
				this.importVisible = true;
			},
			// 刷新
			handleRefresh() {
				this.modifyList()
			},
			// 获取年级列表
			getgradeList() {
				this.$api.setting.getGradeList({}).then(res => {
					if (res.data.code == 1) {
						this.gradeList = res.data.data.rows;
					}
				})
			},
			// 获取班级列表
			getClassList() {
				this.$api.setting.getClassList({
					// filter: JSON.stringify({'grade_id': this.grade_id||this.gradeId})
				}).then(res => {
					this.classList = res.data.rows;
				})
			},
			modifyList() {
				this.loading = true
				let data = {
					page: this.page,
					limit: this.limit,
					filter: ''
				}
				if (this.calssname) {
					data.filter = JSON.stringify({
						class_id: this.calssname,
						grade_id: this.gradeId
					})
				}
				this.$api.sign.schedulingListAll(data)
					.then(res => {
						if (res.data.code == 1) {
							this.list = res.data.data.rows;
							this.total = res.data.data.total;
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				this.page = val;
				this.modifyList();
			},
			handleClose(done) {
				done()
			},
			sosuo() {
				this.page = 1;
				this.modifyList()
			},
		}
	};
</script>

<style lang="scss">
	#tutor {
		.nav-tab-bar-box {
			background-color: #ffffff;
			padding: 15px;
			margin-top: 1.04vw;

			.nav-tab-bar {
				.nav-tab-item {
					color: #6e7a87;
					padding: 0 0.78125vw;
					border-right: 1px solid #f3f3f3;
					cursor: pointer;
					-webkit-touch-callout: none;
					-webkit-user-select: none;
					-khtml-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;
				}

				&div:last-child {
					border-right: none;
				}

				.active {
					font-size: 0.833vw;
					color: #63acff;
					position: relative;
					font-weight: bold;
				}

				.active::after {
					position: absolute;
					content: '';
					height: 2px;
					left: 0.78125vw;
					right: 0.78125vw;
					background-color: #63acff;
					bottom: -0.78125vw;
				}
			}
		}

		.head {
			background-color: #ffffff;
			padding: 10px 30px;
			margin-top: 15px;

			.span {
				margin-right: 10px;
				background-color: #f9f9f9;
				color: #8d9da6;
				padding: 3px 10px;
			}
		}

		.content {
			margin-top: 20px;
			background-color: #ffffff;
			height: 78vh;

			.left {
				border-right: 1px solid #eeeeee;
				height: 78vh;
				min-width: 300px;

				.add {
					background-color: #f8f8f8;
					padding: 10px 0;
					font-size: 24px;
					color: #8d9da6;
					cursor: pointer;
				}

				.add:hover {
					color: #007aff;
				}

				.building-list {
					padding: 25px;
					height: 74vh;
					overflow-y: auto;

					.item {
						padding: 0 15px;
						height: 40px;
						line-height: 40px;
						margin-top: 15px;
						background-color: #f8f8f8;

						.el-icon-caret-right,
						.el-icon-caret-bottom {
							color: #a4a4a4;
							margin-right: 5px;
							font-size: 16px;
						}

						.el-checkbox {
							display: block;
						}

						.btn {
							i {
								background-color: #e8eaec;
								color: #a4a4a4;
								width: 25px;
								height: 25px;
								margin-left: 10px;
								border-radius: 50%;
							}

							i:hover {
								color: red;
							}
						}
					}

					.floor-list {
						background-color: #f8f8f8;
						border-top: 1px solid #eeeeee;
						padding: 0 15px 15px 30px;

						.floor-item {
							background-color: #f1f1f1;
							padding: 0 15px 0 25px;
							color: #4c5559;
							height: 40px;
							line-height: 40px;
							margin-top: 15px;

							.btn {
								i {
									background-color: #ffffff;
									color: #a4a4a4;
									width: 25px;
									height: 25px;
									margin-left: 10px;
									border-radius: 50%;
								}

								i:hover {
									color: red;
								}
							}
						}

						.active {
							background-color: #e4f1ff;
							color: #007aff;
						}
					}
				}
			}

			.right {
				height: 68vh;
				width: 100%;
				padding: 25px 50px;

				.title {
					padding: 0 0 15px 0;
				}
			}

			.suguan {
				padding: 15px 50px 15px 50px;
				height: 70vh;

				.title {
					padding: 0 0 15px 0;
				}
			}
		}

		.el-dialog__header {
			border-bottom: 1px solid #eeeeee;
			padding: 10px 20px;

			.el-dialog__title {
				font-size: 16px;
			}
		}

		.el-dialog__body {
			padding: 0 20px 35px 20px;

			.edit-box {
				.input-box {
					margin-top: 35px;
					padding-left: 15px;

					.input-lebal {
						color: #aeb5b8;
						margin-right: 10px;

						span {
							color: #ff7a65;
							margin-right: 3px;
						}
					}

					.el-icon-remove-outline {
						font-size: 1.5625vw;
						color: #d7d9db;
						margin-left: 10px;
					}

					.input-item {
						.el-select .el-input.is-disabled .el-input__inner {
							cursor: default;
						}

						.el-input.is-disabled .el-input__inner {
							background-color: #ffffff;
							cursor: default;
						}

						.el-input__prefix,
						.el-input__suffix {
							display: none;
						}
					}
				}

				.avatar-uploader .el-upload {
					border: 1px dashed #d9d9d9;
					border-radius: 6px;
					cursor: pointer;
					position: relative;
					overflow: hidden;
				}

				.avatar-uploader .el-upload:hover {
					border-color: #409eff;
				}

				.avatar-uploader-icon {
					font-size: 28px;
					color: #8c939d;
					width: 90px;
					height: 90px;
					line-height: 90px;
					text-align: center;
				}

				.avatar {
					width: 90px;
					height: 90px;
					display: block;
				}
			}
		}

		.el-dialog__footer {
			padding: 15px 20px 15px;
			box-shadow: 0px -5px 10px -5px #eeeeee;
		}

		.el-table__column-filter-trigger {
			.el-icon-arrow-down:before {
				content: '\E790';
				font-size: 16px;
			}
		}
	}

	.selectShow {
		.el-dialog__header {
			border-bottom: 1px solid #eeeeee;
			padding: 10px 20px;

			.el-dialog__title {
				font-size: 16px;
			}
		}

		.el-dialog__body {
			padding: 0;

			.user-list-box {
				.user-list-head {
					padding: 10px 20px;
					border-bottom: 1px solid #eeeeee;

					.user-type-list {
						.user-type-item {
							color: #8d9da6;
							cursor: pointer;
						}

						.active {
							color: #007aff;
							position: relative;
						}

						.active::after {
							position: absolute;
							width: 25px;
							height: 2px;
							background-color: #007aff;
							content: '';
							bottom: -5px;
							left: 0;
						}

						span {
							color: #cccccc;
							margin: 0 8px;
						}
					}
				}

				.user-list-content {
					height: 40vh;

					.left {
						height: 98%;
						overflow-y: auto;
						border-right: 1px solid #eeeeee;
						padding-top: 5px;

						.left-item {
							padding: 0 20px;
							height: 40px;
							line-height: 40px;
							color: #a4a4a4;
							cursor: pointer;
						}

						.active {
							background-color: #e4f1ff;
							color: #2d8cf0;
							position: relative;
						}

						.active:after {
							position: absolute;
							top: 0;
							bottom: 0;
							right: 0;
							width: 2px;
							content: '';
							background-color: #2d8cf0;
						}
					}

					.right {
						height: 37vh;
						padding: 10px 25px;
						overflow-y: auto;

						.list {
							display: flex;
							flex-wrap: wrap;

							.item {
								cursor: pointer;
								padding: 5px 15px;
								border-radius: 3px;
								background-color: #f3f3f3;
								margin-right: 10px;
								margin-bottom: 10px;
							}

							.active {
								background-color: #e4f1ff;
								color: #2d8cf0;
							}
						}
					}
				}
			}
		}

		.dialog-title {
			padding: 10px 0 5px 0;
		}

		.el-dialog__footer {
			padding: 15px 20px 15px;
			box-shadow: 0px -5px 10px -5px #eeeeee;
		}
	}

	.el_option {
		display: none !important;
	}
	
	
	.update_box {
		padding: 40px 20px;
		.update_item {
			background-color: #f6f8fb;
			padding: 20px;
			a {
				color: #007aff;
				text-decoration: none;
			}
		}
		.upload-file{
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			opacity: 0;
			cursor: pointer;
		}
		.file_box{
			padding: 5px;
			.el-icon-circle-check{
				display: block;
			}
			.el-icon-circle-close{
				display: none;
			}
		}
		.file_box:hover{
			color: #007AFF;
			background-color: #F6F8FB;
			.el-icon-circle-check{
				display: none;
			}
			.el-icon-circle-close{
				display: block;
			}
		}
	}
</style>